import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import React, {Suspense} from "react";
import Portfolio, {Loader} from './Portfolio.js'


const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <Canvas
        camera={ {
            fov: 45,
            near: 0.1,
            far: 2000,
            position: [ -3, 1.5, 4 ]
        } }
    >
        <Suspense fallback={<Loader />}>
            <Portfolio />
        </Suspense>
    </Canvas>
)
