import {Text, Html, ContactShadows, OrbitControls, Environment, useGLTF} from '@react-three/drei'
import logPM from './objects/logo1.glb'
import email from './objects/email1.glb'
import github from './objects/github1.glb'
import linkedin from './objects/linkedin1.glb'
import button1 from './objects/button1.glb'
import button2 from './objects/button2.glb'


// loading screen
export function Loader() {
    return <Html className='loading-screen' center={true}>
        <div className="loading-screen">Loading<span className="loading-dot">.</span><span className="loading-dot">.</span><span className="loading-dot">.</span>
        </div>
    </Html>
}

// main application
export default function Portfolio() {
    const computer = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/macbook/model.gltf')
    const logo = useGLTF(logPM)
    const emailIcon = useGLTF(email)
    const githubIcon = useGLTF(github)
    const linkedinIcon = useGLTF(linkedin)
    const buttonIcon1 = useGLTF(button1)
    const buttonIcon2 = useGLTF(button2)


    return <>
        <Environment preset='city'/>

        {/* page background color*/}
        <color args={['#636967']} attach='background'/>

        <OrbitControls
            minAzimuthAngle={-Math.PI / 2}
            maxAzimuthAngle={Math.PI / 12}
            minPolarAngle={Math.PI / 4}
            maxPolarAngle={Math.PI - Math.PI / 2}
            maxDistance={7}
            minDistance={2}
            enablePan={false}
        />

        {/*creates glow simulating screen brightness and color*/}
        <rectAreaLight
            width={2.5}
            height={1.65}
            intensity={65}
            color={'#121212'}
            rotation={[0.1, Math.PI, 0]}
            position={[0, 0.55, -1.15]}
        />
        {/*github object*/}
        <primitive
            object={ githubIcon.scene }
            position={[1.75, 0.1, .25]}
            rotation-x={0}
            rotation-y={3.2}
            rotation-z={0}
            scale={.175}
            onClick={() => window.open('https://github.com/Pmac101?tab=repositories')}
        />
        {/*linkedIn object*/}
        <primitive
            object={ linkedinIcon.scene }
            position={[1.8, .15, .75]}
            rotation-y={-3.1}
            rotation-z={.0}
            scale={.175}
            onClick={() => window.open('https://www.linkedin.com/in/patrick-mccormick-0291a4196/')}
        />
        {/*email object*/}
        <primitive
            object={ emailIcon.scene }
            position={[1.82, .12, 1.25]}
            rotation-x={0}
            rotation-y={0.05}
            rotation-z={0}
            scale={.175}
            onClick={() => window.open('mailto:pmmccormick1@gmail.com')}
        />
        {/*Button: 2D Website*/}
        <primitive
            object={ buttonIcon1.scene }
            position={[-1.8, .9, -1.6]}
            rotation-x={1.35}
            rotation-y={1.6}
            scale={.4}
            onClick={() => window.open('https://pmmccormick2d.netlify.app/')}
            >
        </primitive>
        {/*Button: Hangar 3 Website*/}
        <primitive
            object={ buttonIcon2.scene }
            position={[-1.8, .4, -1.46]}
            rotation-x={1.35}
            rotation-y={1.6}
            scale={.4}
            onClick={() => window.open('https://www.pm-portfolio.com/')}
            >
        </primitive>
        {/*Logo object*/}
        <primitive
            object={ logo.scene }
            position={[1.6, .2, -1]}
            rotation-x={1.55}
            rotation-z={1.5}
            scale={.75}
        />
        {/*laptop object*/}
        <primitive
            object={computer.scene}
            position-y={-1.4}
        >
            {/*creates the iframe being shown on laptop screen*/}
            <Html
                transform
                wrapperClass='htmlScreen'
                distanceFactor={1.17}
                // position of iframe
                position={[0, 1.56, -1.4]}
                rotation-x={-0.256}
            >
                <iframe src='https://pmmccormick2d.netlify.app/' />
            </Html>
        </primitive>
        {/*Name text*/}
        <Text
            fontSize={.3}
            position={[1.8, 0.7, 1.1]}
            rotation-y={-1.5}
            // creates line break
            maxWidth={2}
            textAlign='left'
        >
            Patrick McCormick
        </Text>
        {/*2D website text*/}
        <Text
            fontSize={.135}
            position={[-1.97, .91, -1.57]}
            rotation-x={-.23}
            rotation-z={.01}
            maxWidth={.5}
            textAlign='center'
        >
            2D Website
        </Text>
        {/*Hangar 3 text*/}
        <Text
            fontSize={.135}
            position={[-1.965, .4, -1.43]}
            rotation-x={-.23}
            rotation-z={.02}
            maxWidth={1}
            textAlign='center'
        >
            Hangar 3 Website
        </Text>
        {/*creates a shadow below the object*/}
        <ContactShadows position-y={-1.4}
                        opacity={0.4}
                        scale={5}
                        blur={2.4}
        />
    </>
}